/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import IRNav from "../../../components/IRNav"
import IRBG from "../../../components/IRBG"
import Structure from "./structure.png"
import HomeLogo from "../../../components/HomeLogo"

class CorporateStructurePage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>General Corporate Information</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Corporate Structure
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-offset-1">
            <img
              src={Structure}
              style={{
                maxWidth: `60%`,
                display: `block`,
                marginLeft: `10%`,
              }}
            />
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

CorporateStructurePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CorporateStructurePage
